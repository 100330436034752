import CollageComponent from '@wix/thunderbolt-elements/src/components/Collage/viewer/Collage';
import CollageController from '@wix/thunderbolt-elements/src/components/Collage/viewer/Collage.controller';


const Collage = {
  component: CollageComponent,
  controller: CollageController
};


export const components = {
  ['Collage']: Collage
};

